import React from 'react'
import { useSelector,useDispatch } from 'react-redux';
import Footer from '../Footer/Footer';



function ContactUs() {

const isDarkTheme =  useSelector((state) => state.theme.appTheme )
const theme = isDarkTheme == true ? 'false' : 'true'

const fontStyle = isDarkTheme ? '#616161' :'white'
const cardTheme = isDarkTheme ? 'white' : '#424242';
const mainTheme = isDarkTheme ? 'white' : '#616161';
  return (
    <div className='main_container contact_us'  style={{backgroundColor: mainTheme}}>
      
      <div className='footer_main'>
        <h2 style={{color : fontStyle}}>Contact Us</h2>
        <p style={{color : fontStyle}}>(If you have a story, you have our ears, be a citizen journalist and send us your story here. )</p>
        <p style={{color : fontStyle}}>If you want to contribute to www.btvs.org,</p>
        <p style={{color : fontStyle}}>You can mail us at abhinavtiwari2303@gmail.com</p>
      </div>

      <div style={{position:'fixed',bottom:'0px',width:'100%'}}>
      <Footer />
      </div>
     
      
    </div>
  )
}

export default ContactUs