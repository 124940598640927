import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import page from '../Style/page.css'

function Loader() {
  return (
    <div className='loader'>
      
      <div className='circular_loading'>
        <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
      </div>
    </div>
  )
}

export default Loader