import React from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector,useDispatch } from 'react-redux';

function Footer() {

  const isDarkTheme =  useSelector((state) => state.theme.appTheme )
  const theme = isDarkTheme == true ? 'false' : 'true'

  const fontStyle = isDarkTheme ?  'white':'#616161'
  const cardTheme = isDarkTheme ?   '#424242':'white';
  const mainTheme = isDarkTheme ? 'rgb(72,66,66)' :'white' ;

  const dispatch = useDispatch()
  return (
    <div className='footer'  style={{backgroundColor: mainTheme}}>
      <div className='footer_container' color ={fontStyle}>

        <NavLink className={'footer_links'} to='/contact' style={{color:fontStyle}}>Contact Us</NavLink>
        <NavLink className={'footer_links'}  to='/termsandconditions' style={{color:fontStyle}}>Terms & Conditions</NavLink>
        <NavLink className={'footer_links'} to='/privacypolicy' style={{color:fontStyle}}>Privacy Policy</NavLink>


      </div>
    </div>
  )
}

export default Footer