import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import page from "../../Style/page.css";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import {
  collection,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";

import { db } from "../../firebase";
import {
  getLocalStorageData,
  openNewsPage,
  totalReadTime,
  logScroll,
  logMousePosition,
  // openScrollDialog
} from "../../Utils/Utils";
import Footer from "../Footer/Footer";
import ScrollDialog from "../../Components/ScrollDialog";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Components/Loader";
import upArrow from "../../images/swipeUp64.png";

const url = process.env.REACT_APP_NEWS_URL;
const apiKey = process.env.REACT_APP_NEWSAPI;
const NewsCatch_apiKey = process.env.REACT_APP_NEWS_CATCHERAPI_KEY;
const language = getLocalStorageData("lang");

function Bussiness(props) {
  // const newsDataReference = collection(db,'news')

  const newsDataReference = collection(db, "news2023");
  const navigate = useNavigate();

  const [newsData, setnewsData] = useState([]);
  // const [filteredData, setfilteredData] = useState([])

  const [filteredData, setfilteredData] = useState([]);
  const [loadNext, setloadNext] = useState(1);
  const [lastVisibleNews, setlastVisibleNews] = useState();
  const [hideBckToTopBtn, sethideBckToTopBtn] = useState(false);
  const [newsDocSnap, setnewsDocSnap] = useState();
  const [disableLoadMoreBtn, setdisableLoadMoreBtn] = useState(false);
  const [showThatsAllbtn, setshowThatsAllbtn] = useState(false);

  const [homeNewsData, sethomeNewsData] = useState();
  const [data, setData] = useState();
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [newsCatchData, setnewsCatchData] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [newsItem, setnewsItem] = useState();
  const isDarkTheme = useSelector((state) => state.theme.appTheme);
  const theme = isDarkTheme == true ? "false" : "true";

  const fontStyle = isDarkTheme ? "white" : "#616161";
  const cardTheme = isDarkTheme ? "#424242" : "white";
  const mainTheme = isDarkTheme ? "#616161" : "white";
  const dispatch = useDispatch();

  const loadNextContent = async () => {
    console.log("inside loadNextContent");
    // Construct a new query starting at this document,
    // get the next 25 cities.

    const next = await query(
      newsDataReference,
      where("category", "==", "business"),
      orderBy("createdAt", "desc"),
      startAfter(lastVisibleNews),
      limit(12)
    );

    // list out next 5 data
    let nextData = [];
    const nextDoc = await getDocs(next);
    nextDoc.forEach((doc) => {
      // console.log(doc.data(),'next data')
      let id = doc.id;
      let d = doc.data();
      let obj = { id, d };
      nextData.push(obj);
      console.log(nextData, "next data");
    });
    let data = [];
    // data.push(filteredData)
    // data.push(nextData)
    setfilteredData((prevArray) => [...prevArray, ...nextData]);
    console.log(filteredData, "filteredData");
    // debugger
    // const lastVisible
    setlastVisibleNews(nextDoc.docs[nextDoc.docs.length - 1]);
    if (nextDoc.empty) {
      // alert("hello,Ho gaya bhai!")
      setdisableLoadMoreBtn(true);
      setshowThatsAllbtn(true);
    }
  };

  // useEffect for Pagination

  useEffect(() => {
    console.log("inside useEffect Pagination");
    const getPageinationData = async () => {
      // Query the first page of docs
      const first = query(
        newsDataReference,
        where("category", "==", "business"),
        orderBy("createdAt", "desc"),
        limit(12)
      );
      let fileredNews = [];

      const documentSnapshots = await getDocs(first);

      documentSnapshots.forEach((doc) => {
        // console.log(doc.data(),'doc data')
        let id = doc.id;
        let d = doc.data();
        let obj = { id, d };
        fileredNews.push(obj);
      });
      setfilteredData(fileredNews);
      // debugger
      setisLoading(false);
      // Get the last visible document
      const lastVisible =
        documentSnapshots.docs[documentSnapshots.docs.length - 1];
      setlastVisibleNews(lastVisible);
      setnewsDocSnap(documentSnapshots);
      // console.log("last", lastVisible);
    };
    getPageinationData();
    // return () => {
    //   second
    // }
  }, [loadNext]);

  // const logScroll = e => {
  //   let lastKnownScrollPosition = window.scrollY;
  //   if(lastKnownScrollPosition > 500 ){
  //     sethideBckToTopBtn(true)
  //   }
  //   else {
  //     sethideBckToTopBtn(false)

  //   }

  // }
  // const logMousePosition = e => {
  //   console.log('Mouse event')
  //   setX(e.clientX)
  //   setY(e.clientY)

  // }
  useEffect(() => {
    let verticalY = window.screenTop;
    console.log(verticalY, "verticalY");

    window.addEventListener("mousemove", logMousePosition);
    window.addEventListener("scroll", () => {
      let scrollY = logScroll();
      if (scrollY > 500) {
        sethideBckToTopBtn(true);
      } else {
        sethideBckToTopBtn(false);
      }
    });

    // return () => {
    //   second
    // }
  }, []);

  // useEffect(() => {

  //   const getNews = async ()=>{
  //     const data = await getDocs(newsDataReference)
  //     console.log(data.docs,'data in useEffect')
  //     setnewsData(data.docs.map((doc) => ({...doc.data(),id: doc.id})))

  //     let fileredNews = [];
  //     const q = query(newsDataReference, where("category", "==", "business"),orderBy('createdAt','desc'));
  //     const querySnapshot = await getDocs(q);
  //     querySnapshot.forEach((doc) => {

  //       let id = doc.id
  //       let d = doc.data()
  //       let obj = {id,d}
  //       fileredNews.push(obj)
  //       // console.log(obj,'obj')
  //       // console.log(doc.id, " => ", doc.data());
  //     });
  //     setfilteredData(fileredNews)
  //     setisLoading(false)

  //   }
  //    getNews()

  // }, [])
  console.log(x, y, "x&y");

  const options = {
    method: "GET",
    url: "https://api.newscatcherapi.com/v2/search",
    params: {
      q: "finance",
      lang: language,
      sort_by: "relevancy",
      page: "1",
      countries: "in",
      topic: "finance",
    },
    headers: {
      "x-api-key": NewsCatch_apiKey,
    },
  };

  const fetchNewsCatcherApi = async () => {
    axios
      .request(options)
      .then(function (response) {
        setnewsCatchData(response.data);
        // console.log(response.data,'response.data in business');
        setisLoading(false);
      })
      .catch(function (error) {
        // console.error(error);
        setisLoading(true);
      });
  };

  const fetchItems = async () => {
    const fetchData = fetch(
      `${url}top-headlines?country=in&category=business&apiKey=${apiKey}`
    )
      .then((res) => res.json())
      .then((res) => {
        try {
          if (res.status == "ok") {
            const data = res;
            sethomeNewsData(data.articles);
            setisLoading(false)(res, "response")(data, "datafromresponse");
          }
        } catch (error) {
          setisLoading(true)(error, "error");
        }
      });
  };

  const openNewsDetail = (data, scrollType) => {
    console.log(open, "inside dialog");
    setOpen(true);
    setScroll(scrollType);
    setnewsItem(data);
    //  return (<ScrollDialog data={data}/>)
  };
  const handleClose = () => {
    // console.log('inside handleclose')
    setOpen(false);
    // setopenItem(false)
  };

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {}

    // return () => {
    //   second
    // }
  }, []);
  console.log(hideBckToTopBtn, "hideBckToTopBtn");

  return (
    <div className="main_container" style={{ backgroundColor: mainTheme }}>
      {!isLoading ? (
        <>
          <title>
            BTVS | Check out the latest news of Politics, Entertainment,
            Business, Health, and many more.
          </title>

          <h1 className="page_header" style={{ color: fontStyle }}>
            {" "}
            Bussiness
          </h1>

          <div align="center">
            {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1562260322205207"
        crossorigin="anonymous"></script> */}
            {/* horizontal_display_ad  */}
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-1562260322205207"
              data-ad-slot="6387899080"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </div>
          <div className="card_container">
            {filteredData &&
              filteredData.map((item, index) =>
                item.d.title.length > 20 && item.d.sub_headline ? (
                  <div key={index} className="card_item">
                    <Card
                      id={index}
                      sx={{ backgroundColor: cardTheme, maxWidth: 345 }}
                    >
                      <CardMedia
                        loading="lazy"
                        component="img"
                        height="140"
                        image={item.d.imageUrl}
                        alt="green iguana"
                        onClick={() =>
                          navigate(
                            `/${item.d.category.toLowerCase()}/${item.id}`
                          )
                        }

                        // onClick={()=>openNewsPage(item)}
                      />
                      <CardContent>
                        <Typography
                          className="news_content_date"
                          variant="body2"
                          color={fontStyle}
                          style={{ textAlign: "left", marginBottom: "3%" }}
                        >
                          {item.d.updatedTime.toUpperCase()}
                        </Typography>
                        <Typography
                          className="news_content"
                          gutterBottom
                          variant="h5"
                          color={fontStyle}
                          component="div"
                          style={{ textAlign: "left" }}
                        >
                          {item.d.title}
                        </Typography>
                        <Typography
                          className="news_content"
                          variant="body2"
                          color={fontStyle}
                          style={{ textAlign: "left" }}
                        >
                          {item.d.sub_headline}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Link
                          className="link_style"
                          to={`/${item.d.category.toLowerCase()}/${item.id}`}
                          state={{ from: item }}
                        >
                          <Button
                            size="small"
                            //  onClick={()=>openNewsDetail(item)}
                          >
                            Read More
                          </Button>
                        </Link>
                        <Typography
                          className="news_content"
                          variant="body2"
                          color={fontStyle}
                          style={{ textAlign: "left" }}
                          sx={{ marginLeft: "40%" }}
                        >
                          {totalReadTime(
                            item.d.title,
                            item.d.sub_headline,
                            item.d.content
                          )}
                        </Typography>
                        {/* <Button onClick={()=>openNewsPage(item)} size="small">Visit Website</Button> */}
                      </CardActions>
                    </Card>

                    {open && (
                      <ScrollDialog
                        data={newsItem}
                        openNewsPage={openNewsPage}
                        setOpen={setOpen}
                        handleClose={handleClose}
                        open={open}
                      />
                    )}
                  </div>
                ) : null
              )}
          </div>
        </>
      ) : (
        <Loader />
      )}

      {!showThatsAllbtn && !isLoading && (
        <div className="LoadMoreBtn">
          <Button
            size="small"
            sx={{}}
            className="readMore"
            variant="outlined"
            onClick={loadNextContent}
            disabled={disableLoadMoreBtn}
          >
            Load more
          </Button>
        </div>
      )}
      {showThatsAllbtn && (
        <div className="ThatsAllBtn">
          <Button size="small" variant="outlined" className="readMore">
            That'all Folks...
          </Button>
        </div>
      )}

      {!isLoading && (
        <>
          <Footer />

          <div>
            <span
              //  className='back_to_top'
              className={`${!hideBckToTopBtn ? "hidden" : ""} back_to_top`}
              onClick={() => window.scrollTo(0, 0)}
            >
              Back to Top
            </span>
            <img
              className="upArrow"
              //  className={`${!hideBckToTopBtn ? 'hidden' : ''} back_to_top`}

              onClick={() => window.scrollTo(0, 0)}
              src={upArrow}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Bussiness;
