import { createSlice,configureStore } from '@reduxjs/toolkit'

const initialState = {
    newsData : []
}

const newsDataSlice = createSlice({    
    name : 'newsData',
    initialState, 
    reducers : {
        newsData : state => {
            console.log(state.newsData,'state in reducers')
            newsData = state.newsData
        },
    }
})

export default newsDataSlice.reducer
export const  { newsData }= newsDataSlice.actions