import { createSlice,configureStore } from '@reduxjs/toolkit'

const initialState = {
    isLoggedIn : false
}

const logInSlice = createSlice({    
    name : 'isLoggedIn',
    initialState, 
    reducers : {
        LogIn : state => {
            console.log(state.isLoggedIn,'login state in reducers')
            state.isLoggedIn = !(state.isLoggedIn)
        },
    }
})

export default logInSlice.reducer
export const  { LogIn }= logInSlice.actions