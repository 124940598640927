import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import politics from "../../images/politics.jpg";
import whatsapp from "../../images/whatsapp64.png";
import Footer from "../Footer/Footer";
import {
  openWhatsAppLink,
  logScroll,
  logMousePosition,
} from "../../Utils/Utils";
import sanitizeHtml from "sanitize-html";
import {
  collection,
  getDoc,
  getDocs,
  doc,
  query,
  where,
} from "firebase/firestore";
import { useSelector, useDispatch } from "react-redux";
import HelmetMetaData from "../../Components/HelmetMetaData";

import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappShareButton,
  LineShareButton,
  LinkedinShareButton,
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  MailruIcon,
  TelegramIcon,
  TwitterIcon,
  WhatsappIcon,
} from "react-share";

import { db } from "../../firebase";
import { async } from "@firebase/util";
import Loader from "../../Components/Loader";

import { Helmet } from "react-helmet";
import upArrow from "../../images/swipeUp64.png";

const themeMode = localStorage.getItem("theme");
console.log(themeMode, "themeMode");
function NewsDetail(props) {
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate()
  // console.log(location,params,'location.from')
  const newsDataReference = collection(db, "news2023");
  // let paramData = []

  // const [isRedirect, setisRedirect] = useState(false)
  // const [data, setdata] = useState([])
  console.log(props, location, params, "props in newsDetail");
  let data = {};
  // let data = {};
  let sourceData = "";
  let isRedirect = false;
  let sourceReference;
  const setTheme = localStorage.getItem("theme");
  const [isLoading, setisLoading] = useState(true);
  const [demo, setdemo] = useState("hello");
  const [sourceId, setsourceId] = useState("");
  const [hideBckToTopBtn, sethideBckToTopBtn] = useState(false);
  const [newsData, setnewsData] = useState([]);
  const [paramData, setparamData] = useState([]);
  const isDarkTheme = useSelector((state) => state.theme.appTheme);
  const theme = isDarkTheme == true ? "false" : "true";

  console.log(isDarkTheme, setTheme, "isDarkTheme_newsDetails");
  // const fontStyle = isDarkTheme ?  'white':'#616161'
  // const cardTheme = isDarkTheme ?   '#424242':'white';
  // const mainTheme = isDarkTheme ? '#616161' :'white' ;
  const fontStyle = setTheme == "true" ? "white" : "#616161";
  const cardTheme = setTheme == "true" ? "#424242" : "white";
  const mainTheme = setTheme == "true" ? "#616161" : "white";

  useEffect(() => {
    const docRef = doc(db, "news2023", params.id);

    const getSingleDoc = async () => {
      // let arr = []
      await getDoc(docRef).then((doc) => {
        //  console.log(doc.data(),doc.id)
        let id = doc.id;
        let d = doc.data();
        let obj = { id, d };
        //  arr.push(obj)
        //  console.log(arr,'arr')
        //  setparamData(arr)
        paramData.push(obj);
      });
      console.log(paramData[0].d.title, params.id, params, "paramm");
      setisLoading(false);
    };
    getSingleDoc();
  }, [params.id]);

  // const unfiltered = source
  // const categoryUpper = !isRedirect ?  data && data.d.category.toUpperCase() : sourceData[0].category.toUpperCase()
  const categoryUpper = !isLoading
    ?  paramData[0]?.d?.category?.toUpperCase()
    : "hello";

  const openWhatsApp = openWhatsAppLink;

  const sanitize = (html) => {
    console.log("inside sanitize ", html);
    return sanitizeHtml(html, {
      allowedTags: [
        "p",
        "strong",
        "pre",
        "div",
        "span",
        "h2",
        "ul",
        "li",
        "img",
        "a",
      ],
      allowedClasses: {
        p: [],
        div: [],
        h2: [],
      },
    });
  };

  useEffect(() => {
    let verticalY = window.screenTop;
    console.log(verticalY, "verticalY");

    window.addEventListener("mousemove", logMousePosition);
    window.addEventListener("scroll", () => {
      let scrollY = logScroll();
      if (scrollY > 500) {
        sethideBckToTopBtn(true);
      } else {
        sethideBckToTopBtn(false);
      }
    });

    // return () => {
    //   second
    // }
  }, []);

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      console.log(error, "error in ads");
    }

    // return () => {
    //   second
    // }
  }, []);

  function createMarkup(content) {
    return { __html: content };
  }
  console.log(isLoading, paramData, "paramData");

  return (
    // <h2>hell</h2>
    <>
      {/* <!-- horizontal_ad --> */}
      <div className="newsDetail_horizontal_ad">
        <ins
          className="adsbygoogle"
          style={{ display: "block", marginTop: "9%" }}
          data-ad-client="ca-pub-1562260322205207"
          data-ad-slot="9660753045"
          data-ad-format="auto"
          data-full-width-responsive="true"
        ></ins>
      </div>
      <div
        className="newsDetail_container"
        style={{ backgroundColor: mainTheme }}
      >
        {/* {console.log(paramData,'paramdata in reder')} */}
        {
          //  !isRedirect
          !isLoading ? (
            <>
              {/* <Helmet>
      <title>Learning React Helmet!</title>
      <meta name='description' content='Beginner friendly page for learning React Helmet.' />
      </Helmet> */}
              <HelmetMetaData
                title={
                  paramData &&
                  paramData[0] &&
                  paramData[0].d &&
                  paramData[0].d.title
                }
                description={
                  paramData && paramData[0] && paramData[0].d.sub_headline
                }
              />

              <div className="category_shareLink ">
                <div>
                  <span onClick={()=>navigate(`/${ paramData[0]?.d?.category}`)}>{categoryUpper}</span>
                </div>
              </div>

              <div
                className="main_container_newsDetail"
                style={{
                  backgroundColor: mainTheme,
                  lineHeight: "2",
                  fontWeight: 500,
                  width: "90%",
                }}
              >
                <div className="" style={{ color: fontStyle }}>
                  <h1 className="topic_title">
                    {paramData &&
                      paramData[0] &&
                      paramData[0].d &&
                      paramData[0].d.title}
                  </h1>
                </div>
                <div className="topic_author" style={{ color: fontStyle }}>
                  by{" "}
                  {!isLoading
                    ? paramData && paramData[0] && paramData[0].d.authorName
                    : null}
                </div>
                <div className="topic_author" style={{ color: fontStyle }}>
                  {!isLoading
                    ? paramData && paramData[0] && paramData[0].d.updatedTime
                    : null}
                </div>
                {/* <div className='share_platform'
            onClick={()=>openWhatsApp(paramData[0].d,params.id)}
            > 
            <img className='whatsApp' src={whatsapp}  />            
          </div> */}
                <div className="social_share">
                  <WhatsappShareButton
                    className="share_buttons"
                    title={
                      paramData &&
                      paramData[0] &&
                      paramData[0].d &&
                      paramData[0].d.title
                    }
                    hashtag={"#news"}
                    url={`https://btvs.org/${params.news}/${params.id}`}
                    image={paramData && paramData[0] && paramData[0].d.imageUrl}
                    content={
                      paramData && paramData[0] && paramData[0].d.sub_headline
                    }
                  >
                    <WhatsappIcon size={48} round={true} />
                  </WhatsappShareButton>
                  <TelegramShareButton
                    className="share_buttons"
                    title={
                      paramData &&
                      paramData[0] &&
                      paramData[0].d &&
                      paramData[0].d.title
                    }
                    hashtag={"#news"}
                    url={`https://btvs.org/${params.news}/${params.id}`}
                    image={paramData && paramData[0] && paramData[0].d.imageUrl}
                    content={
                      paramData && paramData[0] && paramData[0].d.sub_headline
                    }
                  >
                    <TelegramIcon size={48} round={true} />
                  </TelegramShareButton>
                  <TwitterShareButton
                    className="share_buttons"
                    title={
                      paramData &&
                      paramData[0] &&
                      paramData[0].d &&
                      paramData[0].d.title
                    }
                    hashtag={"#news"}
                    url={`https://btvs.org/${params.news}/${params.id}`}
                    image={paramData && paramData[0] && paramData[0].d.imageUrl}
                    content={
                      paramData && paramData[0] && paramData[0].d.sub_headline
                    }
                  >
                      
                    <TwitterIcon size={48} round />
                  </TwitterShareButton>

                  <FacebookShareButton
                    className="share_buttons"
                    title={
                      paramData &&
                      paramData[0] &&
                      paramData[0].d &&
                      paramData[0].d.title
                    }
                    hashtag={"#news"}
                    url={`https://btvs.org/${params.news}/${params.id}`}
                    image={paramData && paramData[0] && paramData[0].d.imageUrl}
                    content={
                      paramData && paramData[0] && paramData[0].d.sub_headline
                    }
                  >
                    <FacebookIcon size={48} round={true} />
                  </FacebookShareButton>

                  <LinkedinShareButton
                    className="share_buttons"
                    title={
                      paramData &&
                      paramData[0] &&
                      paramData[0].d &&
                      paramData[0].d.title
                    }
                    hashtag={"#news"}
                    url={`https://btvs.org/${params.news}/${params.id}`}
                    image={paramData && paramData[0] && paramData[0].d.imageUrl}
                    content={
                      paramData && paramData[0] && paramData[0].d.sub_headline
                    }
                  >
                    <LinkedinIcon size={48} round={true} />
                  </LinkedinShareButton>
                </div>

                <div className="topic_image" style={{ color: fontStyle }}>
                  <img
                    width={"85%"}
                    style={{ borderRadius: "5px" }}
                    src={
                      !isLoading
                        ? paramData && paramData[0] && paramData[0].d.imageUrl
                        : null
                    }
                  />
                </div>

                <div className="topic_content">{/* {data.content} */}</div>
                {/* <div dangerouslySetInnerHTML={sanitize(data && data.content)}/> */}
                <div
                  style={{ color: fontStyle }}
                  dangerouslySetInnerHTML={createMarkup(
                    !isLoading
                      ? paramData && paramData[0] && paramData[0].d.content
                      : null
                  )}
                />
              </div>
            </>
          ) : (
            <Loader />
          )
        }

        {!isLoading && (
          <>
            <Footer />
            <div>
              <span
                className={`${!hideBckToTopBtn ? "hidden" : ""} back_to_top`}
                onClick={() => window.scrollTo(0, 0)}
              >
                Back to Top
              </span>
              <img
                className="upArrow"
                onClick={() => window.scrollTo(0, 0)}
                src={upArrow}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default NewsDetail;
