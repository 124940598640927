import { configureStore, ConfigureStoreOptions } from "@reduxjs/toolkit";
import themeReducer from '../features/theme/themeSlice';
import loggedInReducer from '../features/login/logInSlice'
import newsDataReducer from '../features/newsData/newsDataSlice'
const store = configureStore({
    reducer : {
        theme : themeReducer,
        isLoggedIn : loggedInReducer,
        newsData : newsDataReducer
    }
})

export default store