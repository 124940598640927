import { saveAs } from "file-saver";
import hi from "../MultiLanguage/Hindi.json";
import en from "../MultiLanguage/English.json";
import ScrollDialog from "../Components/ScrollDialog";
import politics from "../images/politics.jpg";
import entertainment from "../images/entertainment.jpg";
import science from "../images/science.jpg";
import sports from "../images/sports.jpg";

export const openNewsPage = (item) => {
  // console.log(item,'inside openNewsPage ');
  // window.open(`/${item.d.category.toLowerCase()}/${item.id}`)
  window.open(`/${item.d.category.toLowerCase()}/${item.id}`);
};

export const downloadImage = (item) => {
  // console.log('inside downloadImage')
  let path =
    "https://devdiscourse.blob.core.windows.net/devnews/26_07_2022_05_19_53_6649627.jpg";
  let extract = item.split("?");

  // console.log(extract,'extracted files')
  saveAs(item[1]);
};

export const openWhatsAppLink = (item, source) => {
  // console.log(item,source,'inside openwhatsAppLink')
  if (source) {
    // window.open(`whatsapp://send?text=https://btvs.org/${item.category}/${source}`);

    window.open(
      `https://api.whatsapp.com/send?text=Check+out+this+news+article%3A+https%3A%2F%2Fwww.btvs.org%2F${item.category}%2F${source}+%0A%0A%23news+article`
    );
    // console.log('inside if of openWhatsAppLink')

    // for image
    // https://api.whatsapp.com/send?text=Check+out+this+news+article%3A+https%3A%2F%2Fwww.your-website.com%2Fnews-article.html+%0A%0A%23news+article%0A%0AImage%3A+https%3A%2F%2Fwww.your-website.com%2Fimages%2Fnews-article-image.jpg
  } else {
    window.open(`https://api.whatsapp.com/send?text=${item.id}`);
    // console.log('inside else of openWhatsAppLink')
  }
  // window.open(`whatsapp://send?text= ${item.link}`);
  // <a href="https://api.whatsapp.com/send?text=www.google.com" data-action="share/whatsapp/share">Share via Whatsapp web</a>
  // shareOpen('https://api.whatsapp.com//send?text=Noida+Twin+Towers+Now+History%2C+What+Happens+Next-https%3A%2F%2Fwww.ndtv.com%2Findia-news%2Fnoida-supertech-twin-towers-brought-down-in-one-of-indias-biggest-demolition-what-happens-next-3293974', 900, 600,'whatsapp');
};

export const shareContent = (item, source) => {
  if (navigator.share) {
    navigator
      .share({
        title: "title",
        url: "url",
      })
      .then(() => console.log("thanks for sharing"))
      .catch(console.error);
  }
};

export const logScroll = (e) => {
  let lastKnownScrollPosition = window.scrollY;
  return lastKnownScrollPosition;
  // if(lastKnownScrollPosition > 500 ){
  //   sethideBckToTopBtn(true)
  // }
  // else {
  //   sethideBckToTopBtn(false)

  // }
};
export const logMousePosition = (e) => {
  // console.log('Mouse event')
  let obj = {
    x: e.clientX,
    y: e.clientY,
  };
  return obj;
  // setX(e.clientX)
  // setY(e.clientY)
};

// export const telegramForwardButton = (url, text = '') => {
//     window.open('https://t.me/share/url?url='.rawurlencode(url).'&text='.rawurlencode($text));
//     return "<a href=\"{$share_url}\">Share</a>";
//   }

export const totalReadTime = (title, sub_headline, main_content) => {
  // console.log('inside totalReadTime')
  const titleLength = title.split(" ").length;
  const sub_headlineLength = sub_headline.split(" ").length;
  const main_contentLength = main_content.split(" ").length;
  // console.log(titleLength,sub_headlineLength,main_contentLength,'world length')
  const totalWords = titleLength + sub_headlineLength + main_contentLength;
  const totalTime = totalWords / 200;
  const num = parseInt(totalTime);
  const readTxt = "min read";
  // console.log(num,'btvs')

  if (num < 1) {
    return `1 ${readTxt}`;
  } else {
    return `${num} ${readTxt}`;
  }

  return totalTime;
  //     Here’s the formula:

  // Get your total word count (including the headline and subhead).
  // Divide total word count by 200. The number before the decimal is your minutes.
  // Take the decimal points and multiply that number by .60. That will give you your seconds.
};

export const getStaticImage = (catagory) => {
  if (catagory == "politics") {
    return politics;
  } else if (catagory == "entertainment") {
    return entertainment;
  } else if (catagory == "science") {
    return science;
  } else if (catagory == "sports") {
    return sports;
  }
};

export const getLocalStorageData = (id) => {
  let data = localStorage.getItem(id);
  return data;
};

export const setLocalStorageData = (id, payload) => {
  let data = localStorage.setItem(id, payload);
};

// export const openScrollDialog = (data) => {
//     <ScrollDialog
//         data = {data}
//     />
// }

export const getLanguageJson = () => {
  let data = getLocalStorageData("lang");
  if (data == "hi") {
    return hi;
  } else {
    return en;
  }
};

export const getLocalTheme = () => {
  let data = getLocalStorageData("theme");
  if (data == "dark") {
    return "dark";
  } else {
    return "light";
  }
};

export const getIsLocalTheme = () => {
  let data = getLocalStorageData("theme");
  if (data == "dark") {
    return true;
  } else {
    return false;
  }
};
export const setLocalTheme = (value) => {
  let isTrue = value == "true" ? true : false;
  setLocalStorageData("theme", isTrue);
};
// export const langChecked = (item)=>{
//     if(item){
//         let isChecked =
//     }
//     return checked
// }
