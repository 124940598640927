import React from 'react'
import page from '../../Style/page.css'
import not_found_img from '../../images/not_found_img.webp'
function NotFoundPage() {
  return (
    <div className='main_container'>
      
      {/* <h3>NotFoundPage!!</h3> */}
      <img src={not_found_img} style={{width:'70%'}} />
    </div>
  )
}

export default NotFoundPage