import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import page from "../../Style/page.css";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  openNewsPage,
  getLocalStorageData,
  totalReadTime,
  logScroll,
  logMousePosition,
} from "../../Utils/Utils";
import Footer from "../Footer/Footer";
import ScrollDialog from "../../Components/ScrollDialog";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import upArrow from "../../images/swipeUp64.png";
import { Helmet } from "react-helmet";

const url = process.env.REACT_APP_NEWS_URL;
const apiKey = process.env.REACT_APP_NEWSAPI;
const NewsCatch_apiKey = process.env.REACT_APP_NEWS_CATCHERAPI_KEY;
const language = getLocalStorageData("lang");

function Health() {
  const navigate = useNavigate();

  const [homeNewsData, sethomeNewsData] = useState();
  const [data, setData] = useState();
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [newsCatchData, setnewsCatchData] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [newsItem, setnewsItem] = useState();
  const [hideBckToTopBtn, sethideBckToTopBtn] = useState(false);

  const isDarkTheme = useSelector((state) => state.theme.appTheme);
  const theme = isDarkTheme == true ? "false" : "true";

  const fontStyle = isDarkTheme ? "white" : "#616161";
  const cardTheme = isDarkTheme ? "#424242" : "white";
  const mainTheme = isDarkTheme ? "#616161" : "white";

  const options = {
    method: "GET",
    url: "https://api.newscatcherapi.com/v2/search",
    params: {
      q: "health",
      lang: language,
      sort_by: "relevancy",
      page: "1",
      countries: "in",
      topic: "health",
    },
    headers: {
      "x-api-key": NewsCatch_apiKey,
    },
  };

  const fetchItems = async () => {
    const fetchData = fetch(
      `${url}top-headlines?country=in&category=health&apiKey=${apiKey}`
    )
      .then((res) => res.json())
      .then((res) => {
        try {
          if (res.status == "ok") {
            const data = res;
            sethomeNewsData(data.articles);
          }
        } catch (error) {}
      });
  };

  const fetchNewsCatcherApi = async () => {
    axios
      .request(options)
      .then(function (response) {
        setnewsCatchData(response.data);
        setisLoading(false);
      })
      .catch(function (error) {
        setisLoading(true);
      });
  };
  useEffect(() => {
    let verticalY = window.screenTop;
    console.log(verticalY, "verticalY");

    window.addEventListener("mousemove", logMousePosition);
    window.addEventListener("scroll", () => {
      let scrollY = logScroll();
      if (scrollY > 500) {
        sethideBckToTopBtn(true);
      } else {
        sethideBckToTopBtn(false);
      }
    });

    // return () => {
    //   second
    // }
  }, []);
  const openNewsDetail = (data, scrollType) => {
    console.log(open, "inside dialog");
    setOpen(true);
    setScroll(scrollType);
    setnewsItem(data);
    //  return (<ScrollDialog data={data}/>)
  };
  const handleClose = () => {
    // console.log('inside handleclose')
    setOpen(false);
    // setopenItem(false)
  };

  useEffect(() => {
    try {
      // fetchItems();
      fetchNewsCatcherApi();
    } catch (error) {}
  }, []);

  return (
    <div className="main_container">
      {!isLoading ? (
        <>
          <Helmet>
            <title>
              BTVS | Check out the latest news of Politics, Entertainment,
              Business, Health, and many more.
            </title>
          </Helmet>
          <h1 className="page_header">This is BTVS Health!</h1>
          <div className="card_container">
            {newsCatchData &&
              newsCatchData.articles.map((item, index) =>
                item.title.length > 20 && item.sub_headline ? (
                  <div key={index} className="card_item">
                    <Card id={index} sx={{ maxWidth: 345 }}>
                      <CardMedia
                        component="img"
                        loading="lazy"
                        height="140"
                        image={item.media}
                        alt="green iguana"
                        onClick={() =>
                          navigate(
                            `/${item.d.category.toLowerCase()}/${item.id}`
                          )
                        }
                      />
                      <CardContent>
                        <Typography
                          className="news_content_date"
                          variant="body2"
                          color={fontStyle}
                          style={{ textAlign: "left", marginBottom: "3%" }}
                        >
                          {item.updatedTime.toUpperCase()}
                        </Typography>
                        <Typography
                          className="news_content"
                          gutterBottom
                          variant="h5"
                          component="div"
                          style={{ textAlign: "left" }}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          className="news_content"
                          variant="body2"
                          color="text.secondary"
                          style={{ textAlign: "left" }}
                        >
                          {item.summary}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Link
                          className="link_style"
                          to={`/${item.d.category.toLowerCase()}/${item._id}`}
                          state={{ from: item }}
                        >
                          <Button
                            size="small"
                            onClick={() => openNewsDetail(item)}
                          >
                            Read More
                          </Button>
                        </Link>
                        <Typography
                          className="news_content"
                          variant="body2"
                          color={fontStyle}
                          style={{ textAlign: "left" }}
                          sx={{ marginLeft: "40%" }}
                        >
                          {totalReadTime(
                            item.d.title,
                            item.d.sub_headline,
                            item.d.content
                          )}
                        </Typography>
                        <Button onClick={() => openNewsPage(item)} size="small">
                          Visit Website
                        </Button>
                      </CardActions>
                    </Card>
                    {open && (
                      <ScrollDialog
                        data={newsItem}
                        openNewsPage={openNewsPage}
                        setOpen={setOpen}
                        handleClose={handleClose}
                        open={open}
                      />
                    )}
                  </div>
                ) : null
              )}
          </div>
        </>
      ) : (
        <div className="circular_loading">
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </div>
      )}

      {!isLoading && (
        <>
          <Footer />

          <div>
            <span
              className={`${!hideBckToTopBtn ? "hidden" : ""} back_to_top`}
              onClick={() => window.scrollTo(0, 0)}
            >
              Back to Top
            </span>
            <img
              className="upArrow"
              onClick={() => window.scrollTo(0, 0)}
              src={upArrow}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Health;
