import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import page from "../../Style/page.css";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {
  getLocalStorageData,
  openNewsPage,
  getStaticImage,
  totalReadTime,
  logScroll,
  logMousePosition,
} from "../../Utils/Utils";
import Footer from "../Footer/Footer";
import ScrollDialog from "../../Components/ScrollDialog";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  collection,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import Loader from "../../Components/Loader";
import upArrow from "../../images/swipeUp64.png";
import { Helmet } from "react-helmet";

export default function Finance() {
  // const newsDataReference = collection(db,'news')
  const newsDataReference = collection(db, "news2023");
  const navigate = useNavigate();

  const [filteredData, setfilteredData] = useState([]);
  const [newsData, setnewsData] = useState([]);

  const [homeNewsData, sethomeNewsData] = useState();
  const [data, setData] = useState();
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [newsCatchData, setnewsCatchData] = useState();
  const [isLoading, setisLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [newsItem, setnewsItem] = useState();

  const [hideBckToTopBtn, sethideBckToTopBtn] = useState(false);

  const isDarkTheme = useSelector((state) => state.theme.appTheme);
  const theme = isDarkTheme == true ? "false" : "true";

  const fontStyle = isDarkTheme ? "white" : "#616161";
  const cardTheme = isDarkTheme ? "#424242" : "white";
  const mainTheme = isDarkTheme ? "#616161" : "white";

  const dispatch = useDispatch();

  useEffect(() => {
    const getNews = async () => {
      const data = await getDocs(newsDataReference);
      console.log(data.docs, "data in useEffect");
      setnewsData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      let fileredNews = [];
      const q = query(
        newsDataReference,
        where("category", "==", "finance"),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let id = doc.id;
        let d = doc.data();
        let obj = { id, d };
        fileredNews.push(obj);
        // console.log(obj,'obj')
        // console.log(doc.id, " => ", doc.data());
      });
      setfilteredData(fileredNews);
      setisLoading(false);
    };
    getNews();
  }, []);

  useEffect(() => {
    let verticalY = window.screenTop;
    console.log(verticalY, "verticalY");

    window.addEventListener("mousemove", logMousePosition);
    window.addEventListener("scroll", () => {
      let scrollY = logScroll();
      if (scrollY > 500) {
        sethideBckToTopBtn(true);
      } else {
        sethideBckToTopBtn(false);
      }
    });

    // return () => {
    //   second
    // }
  }, []);

  return (
    <div className="main_container" style={{ backgroundColor: mainTheme }}>
      {!isLoading ? (
        <>
          <Helmet>
            <title>
              BTVS | Check out the latest news of Politics, Entertainment,
              Business, Health, and many more.
            </title>
          </Helmet>
          <h1 className="page_header" style={{ color: fontStyle }}>
            Finance
          </h1>
          <div className="card_container">
            {filteredData &&
              filteredData.map((item, index) =>
                item.d.title.length > 20 && item.d.sub_headline ? (
                  <div key={index} className="card_item">
                    <Card
                      id={index}
                      sx={{ backgroundColor: cardTheme, maxWidth: 345 }}
                    >
                      <CardMedia
                        component="img"
                        loading="lazy"
                        height="140"
                        image={item.d.imageUrl}
                        alt="green iguana"
                        // onClick={()=>openNewsPage(item)}
                        onClick={() =>
                          navigate(
                            `/${item.d.category.toLowerCase()}/${item.id}`
                          )
                        }
                      />
                      <CardContent>
                        <Typography
                          className="news_content_date"
                          variant="body2"
                          color={fontStyle}
                          style={{ textAlign: "left", marginBottom: "3%" }}
                        >
                          {item.d.updatedTime}
                        </Typography>
                        <Typography
                          className="news_content"
                          gutterBottom
                          variant="h5"
                          color={fontStyle}
                          component="div"
                          style={{ textAlign: "left" }}
                        >
                          {item.d.title}
                        </Typography>
                        <Typography
                          className="news_content"
                          variant="body2"
                          color={fontStyle}
                          style={{ textAlign: "left" }}
                        >
                          {item.d.sub_headline}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Link
                          className="link_style"
                          to={`/${item.d.category.toLowerCase()}/${item.id}`}
                          state={{ from: item }}
                        >
                          <Button
                            size="small"
                            //  onClick={()=>openNewsDetail(item)}
                          >
                            Read More
                          </Button>
                        </Link>
                        <Typography
                          className="news_content"
                          variant="body2"
                          color={fontStyle}
                          style={{ textAlign: "left" }}
                          sx={{ marginLeft: "40%" }}
                        >
                          {totalReadTime(
                            item.d.title,
                            item.d.sub_headline,
                            item.d.content
                          )}
                        </Typography>
                        {/* <Button onClick={()=>openNewsPage(item)} size="small">Visit Website</Button> */}
                      </CardActions>
                    </Card>

                    {/* {open && <ScrollDialog data={newsItem}  openNewsPage={openNewsPage} setOpen={setOpen} handleClose={handleClose} open={open}/>} */}
                  </div>
                ) : null
              )}
          </div>
        </>
      ) : (
        // <div className='circular_loading'>
        // <Box sx={{ display: 'flex' }}>
        // <CircularProgress />
        // </Box>
        // </div>
        <Loader />
      )}

      {!isLoading && <Footer />}

      {
        <div>
          <span
            className={`${!hideBckToTopBtn ? "hidden" : ""} back_to_top`}
            onClick={() => window.scrollTo(0, 0)}
          >
            Back to Top
          </span>
          <img
            className="upArrow"
            onClick={() => window.scrollTo(0, 0)}
            src={upArrow}
          />
        </div>
      }
    </div>
  );
}
