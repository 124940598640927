import React,  {useState,useEffect} from 'react'

import {
   
    Routes,
    Route,
    Link,Navigate,
  } from "react-router-dom";
import About from '../Views/About/About';
import Blog from '../Views/Blog/Blog';
import NewsDetail from '../Views/NewsDetail/NewsDetail';
import Bussiness from '../Views/Bussiness/Bussiness';
import ContactUs from '../Views/ContactUs/ContactUs';
import Entertainment from '../Views/Entertainment/Entertainment';
import Footer from '../Views/Footer/Footer';
import Health from '../Views/Health/Health';
import Home from '../Views/Home/Home';
import Politics from '../Views/Politics/Politics';
import PrivacyPolicy from '../Views/PrivacyPolicy/PrivacyPolicy';
import Science from '../Views/Science/Science';
import Sports from '../Views/Sports/Sports';
import TermsAndConditions from '../Views/Terms&Conditions/TermsAndConditions';
import Navbar from './Navbar';
import NotFoundPage from '../Views/NotFoundPage/NotFoundPage';
import QuillEditor from '../Components/richtexteditor/QuillEditor'
import Protected from '../Components/Protected/Protected';
import { Login } from '../Views/Login/Login';
import { useSelector,useDispatch } from 'react-redux'
import { getLocalStorageData } from '../Utils/Utils';
import Finance from '../Views/Finance/Finance';
import { useLocation } from 'react-router-dom';
const isLoggedInLocal = getLocalStorageData('isLoggedIn')

function AppRouter(props) {
  const location = useLocation()
  // const {state} = location  
  // console.log(state.from.d.category,'state from location')
  const [isLoggedIn, setisLoggedIn] = useState(useSelector((state) =>state.isLoggedIn.isLoggedIn) || isLoggedInLocal);
  console.log(isLoggedIn,props,location,'isLoggedIn in AppRouter')
  // useEffect(() => {
  //   <Navigate to="/post_news" replace={true} />

  
  //   // return () => {
  //   //   second
  //   // }
  // }, [isLoggedIn])
  
  // const logIn = () => {
  //   console.log('inside login ')
  // setisLoggedIn(true);
  // };
  // const logOut = () => {
  // setisLoggedIn(false);
  // console.log('inside logout')
  // };

  return (
   
      <div>
        <Navbar />
        {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Routes>
          <Route path="/" exact element={<Home/>}/>
          <Route path="/sports" element={<Sports />} />          
          <Route path ="/entertainment" element= {<Entertainment/>}/> 
          <Route path="/science" element={<Science/>} />
          <Route path="/health" element={<Health />} />
          <Route path="/politics" element={<Politics/>} />
          <Route path="/bussiness" element={<Bussiness />} />
          <Route path="/finance" element={<Finance />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/blog" exact element={<Blog />} />
          <Route path="/:news/:id" element={<NewsDetail  />} />
          <Route  path = "/login" element = {<Login />}/>
          {/* <Route path="/post_news" element={<QuillEditor placeholder={'Write something...'}   />} /> */}
          <Route path="/post_news" element={
             <Protected isLoggedIn={isLoggedIn}>
             <QuillEditor />
             </Protected>
          } />
          
          <Route path="*" element={<NotFoundPage  />} />

        </Routes>
        {/* <Footer /> */}
      </div>
    
  )
}

export default AppRouter