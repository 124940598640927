import {
  collection,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
} from "firebase/firestore";
import { db } from "../../firebase";
import React, { useState, useEffect } from "react";
import politics_img from "../../images/politics.jpg";
import Footer from "../Footer/Footer";
import sanitizeHtml from "sanitize-html";
import { useSelector, useDispatch } from "react-redux";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import page from "../../Style/page.css";
import { openNewsPage, totalReadTime } from "../../Utils/Utils";
import { Helmet } from "react-helmet";
import upArrow from "../../images/swipeUp64.png";

function Blog() {
  //save the news data
  const [newsData, setnewsData] = useState([]);
  // const newsDataReference = collection(db,'news')
  const newsDataReference = collection(db, "news2023");
  const [isLoading, setisLoading] = useState(true);

  const isDarkTheme = useSelector((state) => state.theme.appTheme);
  const theme = isDarkTheme ? "false" : "true";
  console.log(isDarkTheme, "drkthm");

  const fontStyle = isDarkTheme ? "white" : "#616161";
  const cardTheme = isDarkTheme ? "#424242" : "white";
  const mainTheme = isDarkTheme ? "#616161" : "white";

  const dispatch = useDispatch();
  // query the data in useeffect
  useEffect(() => {
    const getNews = async () => {
      const data = await getDocs(newsDataReference);
      console.log(data.docs, "data in useEffect");
      setnewsData(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));

      // filter

      let fileredNews = [];
      const q = query(
        newsDataReference,
        where("category", "==", "Blog"),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        let id = doc.id;
        let d = doc.data();
        let obj = { id, d };
        fileredNews.push(obj);
        console.log(obj, "obj");
        // console.log(doc.id, " => ", doc.data());
      });
      setnewsData(fileredNews);

      setisLoading(false);
    };
    getNews();
  }, []);

  const sanitize = (html) => {
    console.log("inside sanitize ", html);
    return sanitizeHtml(html, {
      allowedTags: [
        "p",
        "strong",
        "pre",
        "div",
        "span",
        "h2",
        "ul",
        "li",
        "img",
        "a",
      ],
      allowedClasses: {
        p: [],
        div: [],
        h2: [],
      },
    });
  };

  function createMarkup(content) {
    return { __html: content };
  }

  const blogDetail = () => {
    console.log("inside blog detail");
  };
  return (
    <div className="main_container" style={{ backgroundColor: mainTheme }}>
      {!isLoading ? (
        <>
          <h1 className="page_header">This is BTVS Blog!</h1>
          <div className="card_container">
            {newsData.map((news, index) => {
              return (
                <>
                  <Helmet>
                    <title>
                      BTVS | Check out the latest news of Politics,
                      Entertainment, Business, Health, and many more.
                    </title>
                  </Helmet>

                  {/* <div key={index} className='card_widget'>
            <div className='card_widget_image'>
              <img width='40%'src={news.imageUrl} />
            </div>
            <div className='card_widget_content'>
              <h3> {news.title} 
                </h3>
              <h4> {news.authorName}</h4>
              <h5>Aug 11, 2022 08:27 PM +05:30 IST</h5>
              <div dangerouslySetInnerHTML={sanitize(news && news.content)}/>
              <div dangerouslySetInnerHTML={createMarkup(news && news.content)}/>
            </div>
        </div> */}
                  <div key={index} className="card_item">
                    <Card
                      key={index}
                      sx={{ backgroundColor: cardTheme, maxWidth: 345 }}
                    >
                      <CardMedia
                        component="img"
                        loading="lazy"
                        height="140"
                        image={news.d.imageUrl}
                        alt="green iguana"
                        // onClick={()=>openNewsPage(item)}
                      />
                      <CardContent>
                        <Typography
                          key={index}
                          className="news_content_date"
                          variant="body2"
                          color={fontStyle}
                          style={{ textAlign: "left", marginBottom: "3%" }}
                        >
                          {news.d.updatedTime.toUpperCase()}
                        </Typography>
                        <Typography
                          className="news_content"
                          key={index}
                          gutterBottom
                          variant="h5"
                          color={fontStyle}
                          component="div"
                          onClick={() => openNewsPage(news)}
                        >
                          {news.d.title}
                        </Typography>
                        <Typography
                          className="news_content"
                          variant="body2"
                          color={fontStyle}
                          // dangerouslySetInnerHTML={createMarkup(news && news.content)}
                        >
                          {news.d.sub_headline}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Link
                          className="link_style"
                          to={`/${news.d.category.toLowerCase()}/${news.id}`}
                          state={{ from: news }}
                          color={fontStyle}
                        >
                          <Button
                            size="small"
                            // onClick={()=>openNewsDetail(item)}
                          >
                            Read More
                          </Button>
                        </Link>
                        <Typography
                          className="news_content"
                          variant="body2"
                          color={fontStyle}
                          style={{ textAlign: "left" }}
                          sx={{ marginLeft: "40%" }}
                        >
                          {totalReadTime(
                            news.d.title,
                            news.d.sub_headline,
                            news.d.content
                          )}
                        </Typography>
                        {/* <Button>Visit Website</Button> */}
                      </CardActions>
                    </Card>
                  </div>
                </>
              );
            })}
          </div>
        </>
      ) : (
        <div className="circular_loading">
          <Box sx={{ display: "flex" }}>
            <CircularProgress />
          </Box>
        </div>
      )}

      {/* <div className='topic_catagories'>
          <h2>Topic</h2>
        </div>

        <div className='card_widget'>
          <div className='card_widget_image'>
            <img src={politics_img} />
          </div>
          <div className='card_widget_content'>
            <h3> Why Trinamool Strongman Anubrata Mandal’s Arrest By CBI Is A Big Blow To Mamata Banerjee 
              </h3>
            <h4> Jaideep Majumdar</h4>
            <h5>Aug 11, 2022 08:27 PM +05:30 IST</h5>
          </div>
        </div> */}

      {!isLoading && (
        <>
          <Footer />

          <div>
            <span className="back_to_top" onClick={() => window.scrollTo(0, 0)}>
              Back to Top
            </span>
            <img
              className="upArrow"
              onClick={() => window.scrollTo(0, 0)}
              src={upArrow}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Blog;
