import React, { Component } from 'react'
import ReactQuill from 'react-quill'
import Select from 'react-select'
import '../../../node_modules/react-quill/dist/quill.snow.css'
import PropTypes from 'prop-types'
import { Box, Button, FormControl, Grid, TextField } from '@mui/material'
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import {storage} from '../../firebase'
import {getDownloadURL, ref, uploadBytes} from 'firebase/storage'
import {v4} from 'uuid'
// import ImageUploader from 'quill-image-uploader'

import {collection,getDocs,addDoc,serverTimestamp,Timestamp} from 'firebase/firestore'
import {db} from '../../firebase'
import { async } from '@firebase/util'

  
// Quill.register("modules/imageUploader", ImageUploader);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const options = [
  { value: 'Blog', label: 'Blog' },
  { value: 'Sports', label: 'Sports' },
  // { value: 'strawberry', label: 'Strawberry' },
  { value: 'politics', label: 'Politics' },
  { value: 'entertainment', label: 'Entertainment' },
  { value: 'science', label: 'Science' },
  { value: 'business', label: 'Business' },
  { value: 'finance', label: 'Finance' },
  { value: 'home', label: 'Home' },
]


// const newsDataReference = collection(db,'news')
const newsDataReference = collection(db,'news2023')

export default class Editor extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
         editorHtml: '', 
         theme: 'snow',
         category: '',
         disable_submit:true,
         inputImage :'',
         headline: '',
         author:'',
         sub_headline: '',
         updatedTime : '',
         addNews:{
            authorName: '',
            content : '',
            createdTime:'',
            // category:'',
            image: '',
            title:'',
            updatedTime:'',
        },
         newsData : {},
         
         }      

      this.handleChange = this.handleChange.bind(this)
      this.handleChangeTextfield = this.handleChangeTextfield.bind(this)
      this.handleCategory = this.handleCategory.bind(this)
    }
    
    handleChange (html) {
        this.setState({ editorHtml: html });
        
    }
    
    handleThemeChange (newTheme) {
      if (newTheme === "core") newTheme = null;
      this.setState({ theme: newTheme })
    }

    submitNews = async () => {
      
      const { 
        category,
        newsData,
        editorHtml,
        addNews,
        inputImage,
        headline,
        sub_headline,
        author,
        updatedTime,
        
      } = this.state

      const months = ['jan','feb','march','april','may','jun','july',
      'aug','sept','oct','november','december']

      const dates = ['01','02','03','04','05','06','07','08','09','10','11','12','13',
               '14','15','16','17','18','19','20','21','22','23','24','25','26','27','28','29','30','31']
      const d = new Date();
      const year = d.getFullYear()
      const date =  d.getDate()
      const month  = months[d.getMonth()]
      const hours = d.getHours()
      const mins = d.getMinutes()
      const minutes = d.getMinutes();
      const actualDate = new Date(date,month,hours,minutes)
      const date_string = `${month} ${date}, ${year} ${hours}:${minutes} IST`


      
      
      debugger
      console.log(headline,sub_headline,'headline & subheadline')
      const imageName = inputImage.name.split('.')
      let imageNameFormat = inputImage.name.substring(inputImage.name.lastIndexOf('.'))
      console.log(imageNameFormat,imageName,'imageNameFormat')

      const imageStorageAddress = `images/${category}/${imageName[0] + v4() + imageNameFormat}`
      let imageUrl;
      const imageRef = await ref(storage,`images/${category}/${imageName[0] + v4() + imageNameFormat}`)
      await uploadBytes(imageRef,inputImage).then(()=>{
        console.log('Image uploaded')
      })
      await getDownloadURL( imageRef).then((url)=>{
         imageUrl = url;
        //  console.log(imageUrl,'imageUrl')
      })
      .catch((error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case 'storage/object-not-found':
           console.log('File doesn\'t exist')
            break;
          case 'storage/unauthorized':
            console.log('User doesn\'t have permission to access the object')
            break;
          case 'storage/canceled':
            console.log('User canceled the upload')
            break;
    
          // ...
    
          case 'storage/unknown':
            console.log('Unknown error occurred, inspect the server response')
            break;
        }
      });
      // const childRef = imageRef.parent
      // const rootRef = childRef.root
      // console.log(imageRef.toString(),'imageRef')
      // console.log(imageUrl,'imageUrl')
      // let date = new Date();
      let payload = {
        content: editorHtml,
        category : category,
        authorName : author,
        updatedTime : date_string,
        title: headline,
        sub_headline : sub_headline,
        imageUrl : imageUrl,
        image : imageStorageAddress,
        createdAt : serverTimestamp(),
      }
      console.log(payload,'payload')

      await addDoc(newsDataReference,payload)
      

     
      // window.location.reload()
      // alert('Content uploaded')
      console.log(payload,'News Submitted !!')
      alert('Submitted')
      window.location.reload()

    }

    uploadImage = (e) =>{
      // console.log(e,'inside uploadImage')
      this.setState({
        ...this.state,
        inputImage : e.target.files[0]
      })
    }

    checkDisableStatus = () =>{
      const { 
        category,
        newsData,
        editorHtml,
        addNews,
        inputImage,
        headline,
        sub_headline,
        author,
        updatedTime,
        
      } = this.state
      console.log(this.state,'inside line 177')


      if(editorHtml && editorHtml.length > 0 && category && category.length >  0 && author && author.length> 0 &&  headline && headline.length >0 && sub_headline && sub_headline.length>0 && inputImage && inputImage.name.length > 0){
        console.log('inside line 193')
        return false
      } else {
        return true
      }
    }

    handleChangeTextfield = (e) =>{
      const {name,value} = e.target
      // console.log(name,value,'yolo')
      this.setState({
        ...this.state,
        [name] :  value
      })
      // console.log(e.target.value,e.target.name,'e in handle change')
    }
    handleCategory = (e) =>  {
      const {value,label } = e

      this.setState({
        ...this.state,
        category : value
      })
      console.log(this.state.addNews,'addnews inside handle category')
    }
    
    render () {
      const  { editorHtml,addNews,inputImage,disable_submit } = this.state
      // console.log(editorHtml,addNews, inputImage,'editor html')
      return (
        <div className='editor_container'>
          <h2 style={{marginTop:'10%',fontSize: '28px'}}>Post Your news!!</h2>

          <Box sx={{ flexGrow: 1,margin:'inherit' }}>
              <Grid container spacing={2}>            
                  <Grid item xs={6} md={8}>
                    <FormControl  className = "input_boxes" sx={{ m: 2, minWidth: 800 }}>
                    <div className='news_topic_select'>
                    <Select options={options} onChange={ this.handleCategory} />
                    </div>
                    </FormControl>
                  </Grid>
              </Grid>

              <Grid container spacing={2}>            
                  <Grid item xs={6} md={8}>
                    <FormControl  className = "input_boxes_img" sx={{ m: 2, minWidth: 800 }}>
                    <div className='news_topic_select'>
                    <input style={{ border:'1px solid',minWidth: 800}} type="file" onChange={this.uploadImage} required/>
                    </div>
                    </FormControl>
                  </Grid>
              </Grid>

              <Grid container spacing={2}>            
                  <Grid item xs={8} md={8}>
                    <FormControl  className = "input_boxes_img" sx={{ m: 2, minWidth: 800 }}>
                      <TextField label={'Headline'} name = 'headline' onChange={this.handleChangeTextfield} required/>
                    </FormControl>
                  </Grid>
              </Grid>

              <Grid container spacing={2}>            
                  <Grid item xs={6}>
                    <FormControl  className = "input_boxes_img" sx={{ m: 2, minWidth: 800 }}>
                      <TextField label={'Sub Headline'} name = 'sub_headline' onChange={this.handleChangeTextfield} required />
                    </FormControl>
                  </Grid>
              </Grid>

              <Grid container spacing={2}>            
                  <Grid item xs={6}>
                    <FormControl  className = "input_boxes_img" sx={{ m: 2, minWidth: 800 }}>
                      <TextField label={'Author'} name = 'author' onChange={this.handleChangeTextfield} required/>
                    </FormControl>
                  </Grid>
              </Grid>

              {/* <Grid container spacing={2}>            
                  <Grid item xs={6}>
                    <FormControl  className = "input_boxes_img" sx={{ m: 2, minWidth: 200 }}>
                      <TextField label={'Updated Time'} name = 'updatedTime' onChange={this.handleChangeTextfield} required />
                    </FormControl>
                  </Grid>
              </Grid> */}
              
          </Box>
          <div className='quill_editor'>
          <ReactQuill 
           style={{ height: 240, width: '90%',margin:'auto', marginTop: '10%',marginBottom: '5%'}}
            theme={this.state.theme}
            onChange={this.handleChange}
            value={this.state.editorHtml}
            modules={Editor.modules}
            formats={Editor.formats}
            bounds={'.app'}
            placeholder={this.props.placeholder}
           />
           </div>
          {/* <div className="themeSwitcher">
            <label>Theme </label>
            <select onChange={(e) => 
                this.handleThemeChange(e.target.value)}>
              <option value="snow">Snow</option>
              <option value="bubble">Bubble</option>
              <option value="core">Core</option>
            </select>
          </div> */}
          



          <div className='submit_news' >
          <Button variant="contained" disabled={this.checkDisableStatus()} onClick={this.submitNews}>
            Submit
          </Button>
          </div>
         </div>
       )
    }
  }

  /* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */
Editor.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    [{size: []}],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, 
     {'indent': '-1'}, {'indent': '+1'}],
    ['link', 'image', 'video'],
    ['clean']
  ],
  // imageUploader: {
  //   upload: file => {
  //     return new Promise((resolve, reject) => {
  //       const formData = new FormData();
  //       formData.append("image", file);

  //       fetch(
  //         "https://api.imgbb.com/1/upload?key=d36eb6591370ae7f9089d85875e56b22",
  //         {
  //           method: "POST",
  //           body: formData
  //         }
  //       )
  //         .then(response => response.json())
  //         .then(result => {
  //           console.log(result);
  //           resolve(result.data.url);
  //         })
  //         .catch(error => {
  //           reject("Upload failed");
  //           console.error("Error:", error);
  //         });
  //     });
  //   }
  // },
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
}
/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
]

/* 
 * PropType validation
 */
Editor.propTypes = {
  placeholder: PropTypes.string,
}
