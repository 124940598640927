import React,{useState,useEffect} from 'react'
import { Button } from '@mui/material'
import '../../../src/Style/page.css'
import { auth, db } from '../../firebase'
import { collection } from 'firebase/firestore'
import { getLocalStorageData, setLocalStorageData } from '../../Utils/Utils'
import { signInWithEmailAndPassword,onAuthStateChanged, signOut } from 'firebase/auth'
import { LogIn } from '../../features/login/logInSlice'
import { useSelector,useDispatch } from 'react-redux'
import {Redirect} from 'react-router'
import { Navigate,Link,useNavigate } from 'react-router-dom'

import { async } from '@firebase/util'


const isLoggedInLocal = getLocalStorageData('isLoggedIn')


export const Login = (props) => {
    // const { login, logout} = props
    const navigate = useNavigate()
    const [user, setuser] = useState({})
    const [loginEmail, setloginEmail] = useState("")
    const [loginPassword, setloginPassword] = useState("")
    const userRef =  collection(db,'users')
    const isLoggedIn = useSelector((state) =>state.isLoggedIn.isLoggedIn)

    const dispatch = useDispatch();
   console.log(useSelector((state) =>state.isLoggedIn.isLoggedIn),'yello')
    console.log(isLoggedIn,'isLoggedIn')
    // console.log(login,logout,'login logout')


    const logIn = async () =>{
      console.log(loginEmail,loginPassword,'credentials')
      try{
        const user = await signInWithEmailAndPassword(auth,loginEmail,loginPassword)
        console.log(user.user.email,'user in login')
        setuser(user.user)
        setLocalStorageData('isLoggedIn',true)
        // window.location.reload()
        dispatch(LogIn())
        // history.push('/',{isLoggedIn:true})
        // <Link  to={location => ({ ...location, pathname: "/courses" })} />  
        // const location = {
        //   pathname: '/somewhere',
        //   state: { fromDashboard: true }
        // }
        // <Link to="/somewhere"/>
        // <Redirect to ={location} />
        // navigate.push('/',{isLoggedIn : true})
      
        // navigator.pushState('/post_news',{isLoggedIn: true})
        // props.login
      } catch(error){
        console.log('error message',error)
        // dispatch(LogIn())
        // props.login() 
      }
    }

    const logOut = async () =>{
      
      console.log('inside logOut')
      await signOut(auth)
      dispatch(LogIn())
      setLocalStorageData('isLoggedIn',false)
      window.location.reload()
    }



    // useEffect(() => {
    //   console.log('inside useEffect')
    // onAuthStateChanged(auth, (currentUser)=>{
    //   setuser(currentUser)
    // })
    
    //   // return () => {
    //   //   second
    //   // }
    // },[])
    
  return (
    <React.Fragment>
      <div className='user_account'> 
      {/* {isLoggedIn} */}

      {isLoggedIn &&
      <>
        {/* <Navigate to="/post_news" replace={true} /> */}

      <h3> Hello { user && user.email}</h3>
        <Button onClick={logOut}>Sign Out</Button>
      </>
      }
      
      </div>
    <div className='log_in'>

        <input 
          name="email"
          type='email'
          placeholder='Enter Your Email'
          required
          onClick={(event)=> setloginEmail(event.target.value)} /><br/>
        <input 
        name="password" 
        type='password' 
        placeholder='Enter Your password' 
        required
        onClick={(event)=> setloginPassword(event.target.value)}
        /><br/>
        <Button variant="contained" onClick={logIn}>
            Submit
        </Button>
        
    </div>
    </React.Fragment>
  )
}
