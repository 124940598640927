import { createSlice,configureStore } from '@reduxjs/toolkit'

const initialState = {
    appTheme : false
}

const themeSlice = createSlice({    
    name : 'appTheme',
    initialState, 
    reducers : {
        darkTheme : state => {
            console.log(state.appTheme,'state in reducers')
            state.appTheme = !(state.appTheme)
        },
    }
})

export default themeSlice.reducer
export const  { darkTheme,lightTheme }= themeSlice.actions