
import React, {useEffect}from 'react'

function Ad(props) {

    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});

        console.log(window,'inside useffect  of adsense')
    //   return () => {
    //     second
    //   }
    }, [])

    const {style,dataAdClient,dataAdSlot,dataAdFormat,dataFullwidthResponsive} = props;
    console.log(props,'propss')
    
  return (
    <div className='goo_ad_horizontal'>
    {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1562260322205207"
   crossorigin="anonymous"></script> */}
    {/* <!-- btvs horzintol ad --> */}
    {/* <ins className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-1562260322205207"
        data-ad-slot="8602413471"
        data-ad-format="auto"
        data-full-width-responsive="true"
    >

    </ins> */}


        {/* <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1562260322205207"
            crossorigin="anonymous"></script> */}
         {/* square card  */}
        <ins className="adsbygoogle"
            style={style}
            data-ad-client={dataAdClient}
            data-ad-slot={dataAdSlot}
            data-ad-format={dataAdFormat}
            data-full-width-responsive={dataFullwidthResponsive}
        >

        </ins>
        <script>
        </script>
   

    </div>
  )
}

export default Ad