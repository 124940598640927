import "./App.css";
import AppRouter from "./Navigation/AppRouter";
import { getLocalStorageData, setLocalStorageData } from "./Utils/Utils";
import { Themeview } from "./features/theme/ThemeView";

import QuillEditor from "./Components/richtexteditor/QuillEditor";
// setLocalStorageData('lang','en');
const language = getLocalStorageData("lang");
const theme = getLocalStorageData("theme");
const isLoggedIn = getLocalStorageData("isLoggedIn");

if (!language) {
  console.log("inside if language");
  setLocalStorageData("lang", "en");
}

if (!theme) {
  console.log("inside if theme");
  setLocalStorageData("theme", false);
}

if (!isLoggedIn) {
  console.log("inside if language");
  setLocalStorageData("isLoggedIn", false);
}

function App() {
  return (
    <div className="App">
      <AppRouter />
    </div>
  );
}

export default App;
