import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import page from "../../Style/page.css";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import axios from "axios";
import { downloadImage, openNewsPage, totalReadTime } from "../../Utils/Utils";
import Ad from "../../Components/GoogleAds/Ad";
import {
  getLocalStorageData,
  getStaticImage,
  logScroll,
  logMousePosition,
} from "../../Utils/Utils";
import Footer from "../Footer/Footer";
import ScrollDialog from "../../Components/ScrollDialog";
import { useSelector, useDispatch } from "react-redux";
import NewsDetail from "../NewsDetail/NewsDetail";
import { Link, useNavigate } from "react-router-dom";
import {
  collection,
  getDoc,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../../firebase";
import Loader from "../../Components/Loader";
import { Helmet } from "react-helmet";
import upArrow from "../../images/swipeUp64.png";

const url = process.env.REACT_APP_NEWS_URL;
const apiKey = process.env.REACT_APP_NEWSAPI;
const NewsCatch_apiKey = process.env.REACT_APP_NEWS_CATCHERAPI_KEY;
const language = getLocalStorageData("lang");
const LINES_TO_SHOW = 2;

console.log(language, "language");
function Home() {
  // const newsDataReference = collection(db,'news')
  const navigate = useNavigate()

  const newsDataReference = collection(db, "news2023");
  const isDarkTheme = useSelector((state) => state.theme.appTheme);
  const theme = isDarkTheme == true ? "false" : "true";
  const dispatch = useDispatch();

  const [filteredData, setfilteredData] = useState([]);
  const [loadNext, setloadNext] = useState(1);
  const [lastVisibleNews, setlastVisibleNews] = useState();
  const [hideBckToTopBtn, sethideBckToTopBtn] = useState(false);

  const [newsDocSnap, setnewsDocSnap] = useState();
  const [disableLoadMoreBtn, setdisableLoadMoreBtn] = useState(false);
  const [showThatsAllbtn, setshowThatsAllbtn] = useState(false);

  const [homeNewsData, sethomeNewsData] = useState();
  const [data, setData] = useState();
  const [newsData, setnewsData] = useState([]);
  const [readMoreClicked, setReadMoreClicked] = useState(false);
  const [newsCatchData, setnewsCatchData] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [newsItem, setnewsItem] = useState();

  const options = {
    method: "GET",
    url: "https://api.newscatcherapi.com/v2/search",
    params: {
      q: "politics",
      lang: language,
      sort_by: "relevancy",
      page: "1",
      countries: "in",
      topic: "politics",
    },
    headers: {
      "x-api-key": NewsCatch_apiKey,
    },
  };

  const loadNextContent = async () => {
    console.log("inside loadNextContent");
    // Construct a new query starting at this document,
    // get the next 25 cities.

    const next = await query(
      newsDataReference,
      orderBy("createdAt", "desc"),
      startAfter(lastVisibleNews),
      limit(12)
    );

    // list out next 5 data
    let nextData = [];
    const nextDoc = await getDocs(next);
    nextDoc.forEach((doc) => {
      // console.log(doc.data(),'next data')
      let id = doc.id;
      let d = doc.data();
      let obj = { id, d };
      nextData.push(obj);
      console.log(nextData, "next data");
    });
    let data = [];
    // data.push(filteredData)
    // data.push(nextData)
    setfilteredData((prevArray) => [...prevArray, ...nextData]);
    console.log(filteredData, "filteredData");
    // debugger
    // const lastVisible
    setlastVisibleNews(nextDoc.docs[nextDoc.docs.length - 1]);
    if (nextDoc.empty) {
      // alert("hello,Ho gaya bhai!")
      setdisableLoadMoreBtn(true);
      setshowThatsAllbtn(true);
    }
  };

  // useEffect for Pagination

  useEffect(() => {
    // console.log('inside useEffect Pagination')
    const getPageinationData = async () => {
      // Query the first page of docs
      const first = query(
        newsDataReference,
        orderBy("createdAt", "desc"),
        limit(12)
      );
      let fileredNews = [];

      const documentSnapshots = await getDocs(first);
      // const data = await documentSnapshots
      // console.log(data,'data')
      // console.log(documentSnapshots.docs,'documentSnapshots ')
      documentSnapshots.forEach((doc) => {
        // console.log(doc.data(),'doc data')
        let id = doc.id;
        let d = doc.data();
        let obj = { id, d };
        fileredNews.push(obj);
      });
      setfilteredData(fileredNews);
      // debugger
      setisLoading(false);
      // Get the last visible document
      const lastVisible =
        documentSnapshots.docs[documentSnapshots.docs.length - 1];
      setlastVisibleNews(lastVisible);
      setnewsDocSnap(documentSnapshots);
      // console.log("last", lastVisible);

      // Construct a new query starting at this document,
      // get the next 25 cities.
      // const next = query(newsDataReference,
      // where("category", "==", "entertainment"),
      // orderBy('createdAt','desc'),
      // startAfter(lastVisible || 0),
      // limit(3));

      // loadNextContent(lastVisible)

      // list out next 5 data
      // const nextDoc = await getDocs(next)
      // nextDoc.forEach((doc) =>{
      //   console.log(doc.data(),'next data')
      //   let id = doc.id
      //   let d = doc.data()
      //   let obj = {id,d}
      //   fileredNews.push(obj)
      // })
      // setfilteredData(fileredNews)

      // console.log(next,'next data')
      // debugger
    };
    getPageinationData();
    // return () => {
    //   second
    // }
  }, [loadNext]);

  // const openNewsDetail = (data,scrollType) => {
  //   console.log(open,data,'inside dialog')
  //   setnewsData(data)
  //   setReadMoreClicked(true)
  //   // setOpen(true)
  //   // setScroll(scrollType);
  //   // setnewsItem(data)

  // //  return (<ScrollDialog data={data}/>)

  // }

  useEffect(() => {
    let verticalY = window.screenTop;
    console.log(verticalY, "verticalY");

    window.addEventListener("mousemove", logMousePosition);
    window.addEventListener("scroll", () => {
      let scrollY = logScroll();
      if (scrollY > 500) {
        sethideBckToTopBtn(true);
      } else {
        sethideBckToTopBtn(false);
      }
    });

    // return () => {
    //   second
    // }
  }, []);

  const handleClose = () => {
    // console.log('inside handleclose')
    setOpen(false);
    // setopenItem(false)
  };

  function openNews(item) {
    ("inside open news");
    window.open(item.link);
  }

  const cardStyle = {
    display: "block",
    transitionDuration: "0.3s",
    height: "45vw",
  };

  const fontStyle = isDarkTheme ? "white" : "#616161";
  const cardTheme = isDarkTheme ? "#424242" : "white";
  const mainTheme = isDarkTheme ? "#616161" : "white";
  // console.log(readMoreClicked,'readMoreClicked')

  // useEffect(() => {

  //   const getNews = async ()=>{
  //     const data = await getDocs(newsDataReference)
  //     const q = query(newsDataReference,orderBy('createdAt','desc'))
  //     // console.log(q,'qdata')
  //     const querySnapshot = await getDocs(q);
  //     let fileredNews = [];

  //     querySnapshot.forEach((doc) => {
  //       // console.log(doc,'docc')
  //       let id = doc.id
  //       let d = doc.data()
  //       let obj = {id,d}
  //       fileredNews.push(obj)
  //       // console.log(obj,'obj')
  //       // console.log(doc.id, " => ", doc.data());
  //     });

  //     // console.log(data.docs,'data in useEffect')
  //     // setnewsData(data.docs.map((doc) => ({...doc.data(),id: doc.id})))
  //     setnewsData(fileredNews)
  //     setisLoading(false)

  //   }
  //    getNews()

  // }, [])

  // console.log(isLoading,'isLoading')
  // console.log(newsData,'flll')

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (error) {
      console.error();
    }

    // return () => {
    //   second
    // }
  }, []);

  return (
    <div className="main_container" style={{ backgroundColor: mainTheme }}>
      {!isLoading ? (
        <>
          <Helmet>
            <Helmet>
              <title>
                BTVS | Check out the latest news of Politics, Entertainment,
                Business, Health, and many more.
              </title>
            </Helmet>
          </Helmet>

          {/* <Ad /> */}
          <h1 className="page_header" style={{ color: fontStyle }}>
            This is BTVS Home!
          </h1>
          {/* <h2>theme {isDarkTheme == true ? 'false' : 'true'} </h2> */}
          <div className="card_container">
            {/*             
        <ins className="adsbygoogle"
            style={{display:'block', textAlign:'center'}}
            data-ad-layout="in-article"
            data-ad-format="fluid"
            data-ad-client="ca-pub-1562260322205207"
            data-ad-slot="7223464454">
        </ins> */}
            {filteredData &&
              filteredData.map((item, index) =>
                item.d.title.length > 20 && item.d.sub_headline ? (
                  <div key={index} className="card_item">
                    {/* <span onClick={()=> downloadImage(item.media)}>Download Image</span> */}
                    <Card
                      id={index}
                      sx={{ backgroundColor: cardTheme, maxWidth: 345 }}
                    >
                      <CardMedia
                        loading="lazy"
                        component="img"
                        height="140"
                        image={
                          item.d.imageUrl
                            ? item.d.imageUrl
                            : getStaticImage(item.d.topic)
                        }
                        alt="green iguana"
                        onClick={() => navigate(`/${item.d.category.toLowerCase()}/${item.id}`)}
                        // defaultValue="download"
                      />

                      <CardContent
                      // onClick = {openNews(item)}
                      >
                        <Typography
                          className="news_content_date"
                          variant="body2"
                          color={fontStyle}
                          style={{ textAlign: "left", marginBottom: "3%" }}
                        >
                          {item.d.updatedTime.toUpperCase()}
                        </Typography>
                        <Typography
                          className="news_content"
                          gutterBottom
                          variant="h5"
                          color={fontStyle}
                          component="div"
                          style={{ textAlign: "left" }}
                        >
                          {item.d.title}
                        </Typography>
                        <Typography
                          className="news_content"
                          variant="body2"
                          color={fontStyle}
                          style={{ textAlign: "left" }}
                        >
                          {item.d.sub_headline}
                        </Typography>
                      </CardContent>
                      <CardActions>
                        <Link
                          className="link_style"
                          sx={{ color: "#ed6c02", listStyle: "none" }}
                          to={`/${item.d.category.toLowerCase()}/${item.id}`}
                          state={{
                            from: { d: item.d, id: item.id },
                            source: "home",
                          }}
                        >
                          <Button
                            size="small"
                            sx={{}}
                            className="readMore"
                            //  onClick={()=>openNewsDetail(item)}
                          >
                            Read More
                          </Button>
                        </Link>
                        <Typography
                          className="news_content"
                          variant="body2"
                          color={fontStyle}
                          style={{ textAlign: "left" }}
                          sx={{ marginLeft: "40%" }}
                        >
                          {totalReadTime(
                            item.d.title,
                            item.d.sub_headline,
                            item.d.content
                          )}
                        </Typography>
                        {/* <Button onClick={()=>openNewsPage(item)} size="small">Visit Website</Button> */}
                      </CardActions>
                    </Card>
                    {open && (
                      <ScrollDialog
                        data={newsItem}
                        openNewsPage={openNewsPage}
                        setOpen={setOpen}
                        handleClose={handleClose}
                        open={open}
                      />
                    )}
                  </div>
                ) : null
              )}
          </div>
        </>
      ) : (
        // <div className='circular_loading'>
        //   <Box sx={{ display: 'flex' }}>
        //   <CircularProgress />
        //   </Box>
        // </div>
        <Loader />
      )}

      {!showThatsAllbtn && !isLoading && (
        <div className="LoadMoreBtn">
          <Button
            size="small"
            sx={{}}
            className="readMore"
            variant="outlined"
            onClick={loadNextContent}
            disabled={disableLoadMoreBtn}
          >
            Load more
          </Button>
        </div>
      )}
      {showThatsAllbtn && (
        <div className="ThatsAllBtn">
          <Button size="small" variant="outlined" className="readMore">
            That'all Folks...
          </Button>
        </div>
      )}

      {readMoreClicked && <NewsDetail data={filteredData} />}

      {!isLoading && (
        <>
          <Footer />

          <div>
            <span
              className={`${!hideBckToTopBtn ? "hidden" : ""} back_to_top`}
              onClick={() => window.scrollTo(0, 0)}
            >
              Back to Top
            </span>
            <img
              className="upArrow"
              onClick={() => window.scrollTo(0, 0)}
              src={upArrow}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default Home;
