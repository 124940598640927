import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import { NavLink, useNavigate } from "react-router-dom";
import nav_header from "./../Style/nav_header.css";
import mobile_menu from "./../Style/mobile_menu.css";
import { darkTheme } from "../features/theme/themeSlice";
import { FormGroup, FormControlLabel } from "@mui/material";
import {
  getLocalStorageData,
  langChecked,
  setLocalStorageData,
  getLanguageJson,
  getLocalTheme,
} from "../Utils/Utils";
// import menu from '../images/menu.png'
import menu from "../images/menu.png";
import close from "../images/close.png";
import { useDispatch, useSelector } from "react-redux";
import BTVS_LOGO from "../images/btvs_logo.png";
import BTVS_LOGO_MOD from "../images/btvs_logo_mod.png";
import lightTheme from "../images/ligth_theme.png";
import moon from "../images/moon.png";
let languageFromLocal = getLocalStorageData("lang") == "hi" ? true : false;
const languageData = getLanguageJson();
let localTheme = localStorage.getItem("theme");

const menuData = languageData.menu;

export default function Navbar() {
  const [checked, setChecked] = React.useState(languageFromLocal);
  const [theme, setTheme] = React.useState(localTheme);
  const [count, setcount] = useState(false);

  const [bgrMenuClicked, setbgrMenuClicked] = useState(false);
  const [closeIcon, setCloseIcon] = useState(false);
  // const isDarkTheme =  useSelector((state) => state.theme.appTheme )
  const isDarkTheme = localTheme == "true" ? true : false;
  // console.log(isDarkTheme,(localTheme),theme,'isDarkTheme_in_navbar')
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    let value = localStorage.getItem("theme");

    setTheme(value == "true" ? true : false);
  }, []);

  const MaterialUISwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,

    "& .MuiSwitch-switchBase": {
      margin: 1,
      padding: 0,
      transform: "translateX(6px)",
      "&.Mui-checked": {
        color: "#fff",
        transform: "translateX(22px)",
        "& .MuiSwitch-thumb:before": {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            "#fff"
          )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
        },
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
      width: 32,
      height: 32,
      "&:before": {
        content: "''",
        position: "absolute",
        width: "100%",
        height: "100%",
        left: 0,
        top: 0,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
          "#fff"
        )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
      },
    },
    "& .MuiSwitch-track": {
      opacity: 1,
      backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      borderRadius: 20 / 2,
    },
  }));

  // const handleThemeChange = (event) => {
  //   console.log('theme selected')
  // }

  const handleChange = (event) => {
    setChecked(event.target.checked);
    // setcount(checked)
    // console.log(event.target.checked,'event target value')
    if (event.target.checked) {
      setLocalStorageData("lang", "hi");
      setChecked(true);
      window.location.reload();
    } else {
      setLocalStorageData("lang", "en");
      setChecked(false);

      window.location.reload();
    }
  };

  const handleThemeChange = (event) => {
    // console.log(event.target.checked,'theme selected')
    dispatch(darkTheme());
    // setTheme(event.target.checked);
    // setcount(checked)
    // console.log(event.target.checked,'event target value')
    if (event.target.checked) {
      setLocalStorageData("theme", true);
    } else {
      setLocalStorageData("theme", false);
    }
  };

  const menuClicked = () => {
    setbgrMenuClicked(!bgrMenuClicked);
    // console.log(bgrMenuClicked,'menu clicked')
  };

  const mobileCloseIconClicked = () => {
    // console.log('inside mobile close icon')

    setbgrMenuClicked(!bgrMenuClicked);
  };

  //  effect to display mobile menu

  useEffect(() => {
    const menu = document.querySelectorAll(".burger_menu_container");
    // console.log(menu,'menu')
    menu.display = "block";
    return () => {};
    
  }, []);

  const redirectToHome = () => {
    // console.log('inside redirecctToHome')
    History.push("/");
  };

  const burgerMenu = () => {
    return (
      <div className="burger_menu_container">
        <div className="burger_menu_container_main">
          <div className="closeIconContainer">
            <img src={close} onClick={() => mobileCloseIconClicked()} />
          </div>
          <div className="nav_header_item_container_mobile">
            <NavLink
              className={"nav_header_item_mobile"}
              to="/"
              onClick={() => mobileCloseIconClicked()}
            >
              {menuData.home}
            </NavLink>
            <NavLink
              className={"nav_header_item_mobile"}
              to="/blog"
              onClick={() => mobileCloseIconClicked()}
            >
              {menuData.blog}
            </NavLink>
            <NavLink className={"nav_header_item_mobile"} to="/sports">
              {menuData.sports}
            </NavLink>
            {/* <NavLink className={'nav_header_item_mobile'} to='/health'>Health</NavLink> */}
            <NavLink
              className={"nav_header_item_mobile"}
              to="/entertainment"
              onClick={() => mobileCloseIconClicked()}
            >
              {menuData.entertainment}
            </NavLink>
            <NavLink
              className={"nav_header_item_mobile"}
              to="/science"
              onClick={() => mobileCloseIconClicked()}
            >
              {menuData.science}
            </NavLink>
            <NavLink
              className={"nav_header_item_mobile"}
              to="/bussiness"
              onClick={() => mobileCloseIconClicked()}
            >
              {menuData.business}
            </NavLink>
            <NavLink
              className={"nav_header_item_mobile"}
              to="/politics"
              onClick={() => mobileCloseIconClicked()}
            >
              {menuData.politics}
            </NavLink>
            <NavLink
              className={"nav_header_item_mobile"}
              to="/finance"
              onClick={() => mobileCloseIconClicked()}
            >
              {menuData.finance}
            </NavLink>
          </div>

          <div className="widgets_mobile">
            <FormGroup>
              {/* Language options
          <FormControlLabel control=
          {
          <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          defaultChecked 
          color="warning"
          />
          }
           label="Hindi"
           /> */}

              <FormControlLabel
                control={
                  <MaterialUISwitch
                    onChange={handleThemeChange}
                    sx={{ m: 1 }}
                    defaultChecked={isDarkTheme}
                  />
                }
                // label="Dark"
              />
            </FormGroup>
          </div>
        </div>
      </div>
    );
  };

  const setThemeToLocal = (e) => {
    // console.log('inside  setThemeLocal')
    // const val = theme == 'true' ? true : false
    // setTheme(!theme)
    localStorage.setItem("theme", !theme);
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  // console.log(theme,'theme_in_navbar')
  return (
    <nav className="nav_header">
      {/* <div className='logo_container'> */}
      {/* <h1 className='logo' onClick={()=>navigate('/')}>BTVS</h1> */}
      <img
        alt="btvs-logo"
        src={BTVS_LOGO_MOD}
        className="btvs_logo"
        onClick={() => navigate("/")}
        // style = {{
        //   width : '8%'
        // }}
      />
      {/* </div>         */}

      <div className="nav_header_item_container">
        <NavLink className={"nav_header_item"} to="/">
          {menuData.home}
        </NavLink>
        <NavLink className={"nav_header_item"} to="/blog">
          {menuData.blog}
        </NavLink>
        <NavLink className={"nav_header_item"} to="/sports">
          {menuData.sports}
        </NavLink>
        {/* <NavLink className={'nav_header_item'} to='/health'>{menuData.health}</NavLink> */}
        <NavLink className={"nav_header_item"} to="/entertainment">
          {menuData.entertainment}
        </NavLink>
        <NavLink className={"nav_header_item"} to="/science">
          {menuData.science}
        </NavLink>
        <NavLink className={"nav_header_item"} to="/bussiness">
          {menuData.business}
        </NavLink>
        <NavLink className={"nav_header_item"} to="/politics">
          {menuData.politics}
        </NavLink>
        {/* <NavLink className={'nav_header_item'} to='/finance'>{menuData.finance}</NavLink> */}
      </div>

      <div className="widgets">
        <FormGroup>
          {/* Language options */}
          {/* <FormControlLabel control=
          {
          <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          defaultChecked 
          color="warning"
          />
          }
           label="Hindi"
           /> */}

          {/* <FormControlLabel
          control={
          <MaterialUISwitch
             onClick={handleThemeChange}
              sx={{ m: 1 }}
               defaultChecked ={isDarkTheme}
               />
          }
          // label="Dark"
          /> */}

          <div onClick={(e) => setThemeToLocal(e)}>
            <img src={`${theme ? moon : lightTheme}`} />
          </div>
        </FormGroup>
      </div>

      {bgrMenuClicked && burgerMenu()}

      <div className="burger_menu" onClick={() => menuClicked()}>
        <img src={menu} width="30px" />
      </div>
      <div>
        {/* <Switch
          disabled
          label="Hindi"
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
        /> */}

        {/* <FormGroup>
          <FormControlLabel control=
          {
          <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ 'aria-label': 'controlled' }}
          defaultChecked 
          />
          }
           label="Hindi"
           />
          
        </FormGroup> */}
      </div>
    </nav>
  );
}
