import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ScrollDialog(props) {
  const [open, setOpen] = React.useState(props.open);
  const [scroll, setScroll] = React.useState('paper');
  const [openItem, setopenItem] = React.useState(props.open)

  // const handleClickOpen = (scrollType) => () => {
  //   setOpen(true);
  //   setScroll(scrollType);
  // };

// const { handleClose} =props.handleClose

const handleClose = () => {
  // console.log('inside handleclose')
  setOpen(false);
  props.setOpen(false)
  // setopenItem(false)
};

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    console.log('inside useeffect')
    if (openItem) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openItem]);
  const data = props.data;
  
  console.log(openItem,'data')
  return (
    <div>
      {/* <Button onClick={handleClickOpen('paper')}>scroll=paper</Button>
      <Button onClick={handleClickOpen('body')}>scroll=body</Button> */}
      <Dialog
        open={openItem}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">{data.title}</DialogTitle>
        <DialogContent dividers={scroll === 'paper'}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {data.summary}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
          {/* <Button onClick={props.openNewsPage}>Click here to visit their website</Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
